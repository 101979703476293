<p-dialog
  header="Header"
  [(visible)]="open"
  (onHide)="closeModal.emit()"
  styleClass="plastic"
>
  <ng-template pTemplate="header">
    <h2 class="profile__subtitle">Profile Overview</h2>
  </ng-template>

  <form class="form profile" [formGroup]="form" (ngSubmit)="saveProfile()">
    <div class="profile-container">
      <section class="section-profile-avatar">
          <label class="profile__avatar">
            <img
              id="imagePreview"
              [src]="user?.profilePicture?.source || '/images/profile.png'"
              alt=""
            />
            <input
              id="avatar-upload"
              type="file"
              formControlName="profilePicture"
              (change)="onFileChange($event)"
              accept="image/png, image/jpeg"
              aria-label="Upload your profile picture"
            />
          </label>
      </section>

      <section class="section-profile-details">
        <div class="profile__block">
          <h2 class="profile__subtitle">Details</h2>
          <div class="form__item">
            <div>
              <span class="p-input-icon-left">
                <i class="pi icon-person"></i>
                <input
                  pInputText
                  type="text"
                  placeholder="Username"
                  formControlName="username"
                />
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="profile-footer mt-5 md:mt-0">
      <p-button label="Save" type="submit" />
    </div>
  </form>
</p-dialog>
