import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { ReadyGGApiConfiguration } from '@readygg/ng-api/ready-gg-api-configuration';
import {
  UserDataService,
} from '@readygg/ng-api';
import { environment } from '../../../environments/environment';
import { UserActions } from '../actions/user.actions';

@Injectable()
export class UserEffects {
  getUserProfile$;
  updateUsername$;
  uploadProfilePicture$;

  constructor(
    private actions$: Actions,
    private userService: UserDataService,
  ) {
    const config: ReadyGGApiConfiguration = {
      rootUrl: environment.apiUrl,
    };

    this.userService = new UserDataService(config, inject(HttpClient));

    this.getUserProfile$ = createEffect(() =>
      this.actions$.pipe(
        ofType(UserActions.getUserProfile),
        switchMap((action) =>
          this.userService.userGetProfile(action.params).pipe(
            map((response) => {
              return UserActions.getUserProfileSuccess({
                profile: response,
              });
            }),
            catchError((error) =>
              of(UserActions.getUserProfileFailure({ error })),
            ),
          ),
        ),
      ),
    );

  this.updateUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUsername),
      mergeMap((action) =>
        this.userService.userUpdateDisplayName(action.params).pipe(
          map((response) => {
            return UserActions.updateUsernameSuccess({
              displayName: response.displayName!,
            });
          }),
          catchError((error) =>
            of(UserActions.updateUsernameFailure({ error })),
          ),
        ),
      ),
    ),
  );

  this.uploadProfilePicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.uploadProfilePicture),
      mergeMap((action) =>
        this.userService.userUploadProfilePicture(action.params).pipe(
          map((response) => {
            return UserActions.uploadProfilePictureSuccess({
              profilePicture: response,
            });
          }),
          catchError((error) =>
            of(UserActions.uploadProfilePictureFailure({ error })),
          ),
        ),
      ),
    ),
  );
  }
}
