<header class="section header">
  <div class="header__right">
    @if (userCredentials?.email) {
    <app-profile [open]="isProfileOpen" (closeModal)="closeProfile()"></app-profile>
    <div class="flex justify-content-center align-items-center">
      <p-menu #menu [model]="items" [popup]="true"></p-menu>
      <a class="block user-icon" *ngIf=" userData !=null">
        <img [src]="userData.profilePicture?.source || '/images/profile.png'" alt="My Profile" />
      </a>
      <div (click)="menu.toggle($event)">
        <i class="settings-toggle mx-2 text-xl pi pi-ellipsis-v"></i>
      </div>
    </div>
    } @else {
    <button class="button log-in__button" pButton severity="primary" [rounded]="true" [outlined]="true"
      (click)="openAuthWebForm()" [loading]="loading">
      <i class="icon-person"></i>
      <span class="font__s">Log In</span>
    </button>
    }
  </div>
</header>