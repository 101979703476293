import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RouterOutlet, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TelegramService } from './data/services/telegram/telegram.service';
import { SearchComponent } from './components/search/search.component';
import { LoaderComponent } from "./components/loader/loader.component";
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    FormsModule,
    HeaderComponent,
    ProfileComponent,
    SearchComponent,
    LoaderComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'play-arcade-center';
  @ViewChild('logo') logo!: ElementRef<HTMLDivElement>;
  items!: MenuItem[];

  constructor(
    telegramService: TelegramService,

  ) {
    telegramService.init();
  }

  ngOnInit(): void {
    this.items = [
      {
        label: 'Games',
        icon: 'assets/images/games.png',
        routerLink: '/games'
      },
      {
        label: 'Quests',
        icon: 'assets/images/quests.png',
        routerLink: '/quests'
      },
      {
        label: 'Rank',
        icon: 'assets/images/events.png',
        routerLink: '/events/play-quests-leaderboard/pkKi0Rd7lCV06CQqmyvv'
      }
    ];
  }

  ngAfterViewInit(): void {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll(): void {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    const logoElement = this.logo.nativeElement;

    // Add class when scroll passes 50px
    if (scrollY > 50) {
      if (!logoElement.classList.contains('is-pinned')) {
        logoElement.classList.add('is-pinned');
      }
    } else {
      if (logoElement.classList.contains('is-pinned')) {
        logoElement.classList.remove('is-pinned');
      }
    }
  }
}
