import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { EMPTY, from, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AchievementsService, ReadyGGApiConfiguration } from '@readygg/ng-api';
import * as AchievementsActions from '../actions/achievements.actions';
import { LeaderboardsActions } from './../actions/leaderboards.actions';
import { AppState } from '../app.state';
import { environment } from '../../../environments/environment';

@Injectable()
export class AchievementsEffects {
  loadAchievements$;
  claimAchievement$;
  triggerAchievement$;

  constructor(
    private actions$: Actions,
    private achievementsService: AchievementsService,
    private store: Store<AppState>,
  ) {
    const config: ReadyGGApiConfiguration = {
      rootUrl: environment.apiUrl,
    };

    this.achievementsService = new AchievementsService(config, inject(HttpClient));

    this.loadAchievements$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AchievementsActions.loadAchievements),
        mergeMap(() =>
          from(
            this.achievementsService.achievementsGetByAppIdsWithUserData({
              body: {
                appIds: [environment.projectId],
                limit: 25,
                withHistory: false,
              },
            })
          ).pipe(
            map((response) =>
              AchievementsActions.loadAchievementsSuccess({
                achievements: response.achievements,
                userAchievements: response.userAchievements,
              })
            ),
            catchError((error) =>
              of(AchievementsActions.loadAchievementsFailure({ error }))
            ),
          )
        )
      )
    );

    this.claimAchievement$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AchievementsActions.claimAchievement),
        mergeMap((action) =>
          this.achievementsService.achievementsClaimById({
            body: {
              appId: environment.projectId,
              id: action.achievementId || '',
            }
          }).pipe(
            map((response) =>
              AchievementsActions.claimAchievementSuccess({
                rewardsHaveBeenGivenOut: response.rewardsHaveBeenGivenOut ?? false,
                rewards: response.rewards ?? [],
                achievementId: action.achievementId
              })
            ),
            tap(() => {
              this.store.dispatch(AchievementsActions.loadAchievements());
              this.store.dispatch(LeaderboardsActions.getUserEntry());
            }),
            catchError((error) =>
              of(AchievementsActions.claimAchievementFailure({ error }))
            ),
          ),
        ),
      )
    );

    this.triggerAchievement$ = createEffect(
      () =>
        this.actions$.pipe(
          ofType(AchievementsActions.triggerAchievement),
          mergeMap((action) =>
            this.achievementsService
              .achievementsTriggerById({
                body: {
                  id: action.achievementId || '',
                  appId: environment.projectId,
                },
              })
              .pipe(
                map((response) => {
                  this.store.dispatch(
                    AchievementsActions.triggerAchievementSuccess({
                      achievementId: action.achievementId,
                      rewardsHaveBeenGivenOut:
                        response.rewardsHaveBeenGivenOut || null,
                      rewards: response.rewards || null,
                    })
                  );
                  this.store.dispatch(
                    AchievementsActions.setAchievementToTrigger({
                      achievement: null,
                    })
                  );
                  this.store.dispatch(
                    AchievementsActions.loadAchievements()
                  );
                  this.store.dispatch(LeaderboardsActions.getUserEntry());
                  return EMPTY;
                }),
                catchError((error) => {
                  console.error('Failed to trigger achievement', error);
                  this.store.dispatch(
                    AchievementsActions.triggerAchievementFailure({
                      error: error,
                    })
                  );
                  this.store.dispatch(
                    AchievementsActions.setAchievementToTrigger({
                      achievement: null,
                    })
                  );
                  return EMPTY;
                })
              )
          )
        ),
      { dispatch: false }
    );
  }
}
