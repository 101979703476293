import { createReducer, on } from '@ngrx/store';
import {
  ReadyGGImageUrl,
  ReadyGGUserData,
} from '@readygg/ng-api';
import { UserActions } from '../actions/user.actions';

export interface UserState {
  profile: ReadyGGUserData | null;
  displayName: string | null;
  profilePicture: ReadyGGImageUrl | null;
  loading: boolean;
}

export const initialState: UserState = {
  profile: null,
  displayName: null,
  profilePicture: null,
  loading: false,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.getUserProfile, (state) => ({
    ...state,
    loading: true,
  })),
  on(UserActions.getUserProfileSuccess, (state, { profile }) => ({
    ...state,
    profile,
    loading: false,
  })),
  on(UserActions.getUserProfileFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(UserActions.updateUsernameSuccess, (state, { displayName }) => ({
    ...state,
    displayName,
  })),
  on(UserActions.updateUsernameFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(UserActions.uploadProfilePictureSuccess, (state, { profilePicture }) => ({
    ...state,
    profilePicture,
  })),
  on(UserActions.uploadProfilePictureFailure, (state, { error }) => ({
    ...state,
    error,
  })),
);
