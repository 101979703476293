import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AdsService, ReadyGGApiConfiguration } from '@readygg/ng-api';
import { from, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AdsActions } from '../actions/ads.actions';

@Injectable()
export class AdsEffects {
    getAdsByTags$;
    getAdsToDisplay$;

    constructor(
        private actions$: Actions,
        private adsService: AdsService,
    ) {
        const config: ReadyGGApiConfiguration = {
            rootUrl: environment.apiUrl,
        };

        this.adsService = new AdsService(config, inject(HttpClient));

        this.getAdsByTags$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AdsActions.getAdsByTags),
                mergeMap((action) =>
                    from(
                        this.adsService.adsGetByTags({
                            body: {
                                tags: action.tags,
                                limit: 5,
                            },
                        })
                    ).pipe(
                        map((response) =>
                            AdsActions.getAdsByTagsSuccess({
                                ads: response.ads ?? [],
                            })
                        ),
                        catchError((error) =>
                            of(AdsActions.getAdsByTagsFailure({ error })),
                        ),
                    ),
                ),
            ),
        );

        this.getAdsToDisplay$ = createEffect(() =>
            this.actions$.pipe(
                ofType(AdsActions.getAdsToDisplay),
                mergeMap(() =>
                    from(
                        this.adsService.adsGetAdsToDisplay({
                            body: {
                                type: 'banner',
                                limit: 100,
                            },
                        })
                    ).pipe(
                        map((response) =>
                            AdsActions.getAdsToDisplaySuccess({
                                ads: response.ads ?? [],
                            })
                        ),
                        catchError((error) =>
                            of(AdsActions.getAdsToDisplayFailure({ error })),
                        ),
                    ),
                ),
            ),
        );
    }
}
