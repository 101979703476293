import { createAction, props } from '@ngrx/store';
import {
  ReadyGGUserData,
  ReadyGGUserProfilePicture,
} from '@readygg/ng-api';
import { UserGetProfile$Params } from '@readygg/ng-api/fn/user-data/user-get-profile';
import { UserUpdateDisplayName$Params } from '@readygg/ng-api/fn/user-data/user-update-display-name';
import { UserUploadProfilePicture$Params } from '@readygg/ng-api/fn/user-data/user-upload-profile-picture';

export const getUserProfile = createAction(
  '[User] Get User Profile',
  props<{ params: UserGetProfile$Params }>(),
);

export const getUserProfileSuccess = createAction(
  '[User] Get User Profile Success',
  props<{ profile: ReadyGGUserData }>(),
);

export const getUserProfileFailure = createAction(
  '[User] Get User Profile Failure',
  props<{ error: any }>(),
);

export const updateUsername = createAction(
  '[User] Update Username',
  props<{ params: UserUpdateDisplayName$Params }>(),
);

export const updateUsernameSuccess = createAction(
  '[User] Update Username Success',
  props<{ displayName: string }>(),
);

export const updateUsernameFailure = createAction(
  '[User] Update Username Failure',
  props<{ error: any }>(),
);

export const uploadProfilePicture = createAction(
  '[User] Update Profile Picture',
  props<{ params: UserUploadProfilePicture$Params }>(),
);

export const uploadProfilePictureSuccess = createAction(
  '[User] Update Profile Picture Success',
  props<{ profilePicture: ReadyGGUserProfilePicture }>(),
);

export const uploadProfilePictureFailure = createAction(
  '[User] Update Profile Picture Failure',
  props<{ error: any }>(),
);

export const UserActions = {
  getUserProfile,
  getUserProfileSuccess,
  getUserProfileFailure,
  updateUsername,
  updateUsernameSuccess,
  updateUsernameFailure,
  uploadProfilePicture,
  uploadProfilePictureSuccess,
  uploadProfilePictureFailure,
};
