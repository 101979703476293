import { createReducer, on } from "@ngrx/store";
import { ReadyGGAdData } from "@readygg/ng-api";
import { AdsActions } from "../actions/ads.actions";

export interface AdsState {
    ads: ReadyGGAdData[];
}
  
export const initialAdsState: AdsState = {
    ads: [],
};

export const adsReducer = createReducer<AdsState>(
    initialAdsState,
    on(AdsActions.getAdsByTagsSuccess, (state, { ads }) => ({
      ...state,
      ads,
    })),
    on(AdsActions.getAdsByTagsFailure, (state, { error }) => ({
      ...state,
      error,
    })),
    on(AdsActions.getAdsToDisplaySuccess, (state, { ads }) => ({
      ...state,
      ads,
    })),
    on(AdsActions.getAdsToDisplayFailure, (state, { error }) => ({
      ...state,
      error,
    }))
);
