import { createReducer, on } from "@ngrx/store";
import { ReadyGGLeaderboardData, ReadyGGLeaderboardEntry } from "@readygg/ng-api";
import { LeaderboardsActions } from "../actions/leaderboards.actions";

export interface LeaderboardsState {
  leaderboards: ReadyGGLeaderboardData[] | undefined;
  activeLeaderboard: ReadyGGLeaderboardData | undefined;
  entries: ReadyGGLeaderboardEntry[] | undefined;
  userEntry: ReadyGGLeaderboardEntry | null;
  error: any | null;
}

export const initialLeaderboardsState: LeaderboardsState = {
  leaderboards: [],
  entries: undefined,
  activeLeaderboard: undefined,
  userEntry: null,
  error: null,
};

export const leaderboardsReducer = createReducer<LeaderboardsState>(
  initialLeaderboardsState,
  on(LeaderboardsActions.getLeaderboardEntries, (state) => ({
    ...state
  })),
  on(LeaderboardsActions.getLeaderboardEntriesSuccess, (state, { entries }) => ({
    ...state,
    entries,
  })),
  on(LeaderboardsActions.clearEntries, (state) => ({
    ...state,
    entries: [],
  })),
  on(LeaderboardsActions.getLeaderboardEntriesFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(LeaderboardsActions.getUserEntrySuccess, (state, { entry }) => ({
    ...state,
    userEntry: entry,
  })),
  on(LeaderboardsActions.getUserEntryFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(LeaderboardsActions.getLeaderboardsByAppIds, (state) => ({
    ...state,
  })),
  on(LeaderboardsActions.getLeaderboardsByAppIdsSuccess, (state, { leaderboards }) => ({
    ...state,
    leaderboards,
  })),
  on(LeaderboardsActions.getLeaderboardsByAppIdsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(LeaderboardsActions.getLeaderboardById, (state) => ({
    ...state,
  })),
  on(LeaderboardsActions.getLeaderboardByIdSuccess, (state, { activeLeaderboard }) => ({
    ...state,
    activeLeaderboard,
  })),
  on(LeaderboardsActions.getLeaderboardByIdFailure, (state, { error }) => ({
    ...state,
    error,
  })),


);
