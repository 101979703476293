import { ActionReducerMap } from '@ngrx/store';
import { AchievementsState, achievementsReducer } from './reducers/achievements.reducer';
import { adsReducer, AdsState } from './reducers/ads.reducer';
import { leaderboardsReducer, LeaderboardsState } from './reducers/leaderboards.reducer';
import { userReducer, UserState } from './reducers/user.reducer';

export interface AppState {
  user: UserState;
  achievements: AchievementsState;
  leaderboards: LeaderboardsState;
  ads: AdsState;
}

export const reducers: ActionReducerMap<AppState> = {
  user: userReducer,
  achievements: achievementsReducer,
  leaderboards: leaderboardsReducer,
  ads: adsReducer,
};
