import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LeaderboardsActions } from './../actions/leaderboards.actions';
import { LeaderboardV2Service, ReadyGGApiConfiguration } from '@readygg/ng-api';
import { from, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { LeaderboardV2GetEntries$Params } from '@readygg/ng-api/fn/leaderboard-v-2/leaderboard-v-2-get-entries';

@Injectable()
export class LeaderboardsEffects {
  getLeaderboardEntries$;
  getUserEntry$;
  getByAppIds$;
  getById$;

  constructor(
    private actions$: Actions,
    private leaderboardsService: LeaderboardV2Service,
    private store: Store<AppState>,
  ) {
    const config: ReadyGGApiConfiguration = {
      rootUrl: environment.apiUrl,
    };

    this.leaderboardsService = new LeaderboardV2Service(config, inject(HttpClient));

    this.getLeaderboardEntries$ = createEffect(() =>
      this.actions$.pipe(
        ofType(LeaderboardsActions.getLeaderboardEntries),
        mergeMap((action: { id: string, isAuthenticated: boolean }) =>
          from(
            this.leaderboardsService.leaderboardV2GetEntries({
              body: {
                appId: environment.projectId,
                leaderboardId: action.id,
                includeUser: action.isAuthenticated,
                quantityAroundUser: 0,
                quantityTop: 50,
              },
            } as LeaderboardV2GetEntries$Params)
          ).pipe(
            map((response) =>
              LeaderboardsActions.getLeaderboardEntriesSuccess({
                entries: response.entries,
              })
            ),
            catchError((error) =>
              of(LeaderboardsActions.getLeaderboardEntriesFailure({ error }))
            ),
          )
        )
      )
    );

    this.getById$ = createEffect(() =>
      this.actions$.pipe(
        ofType(LeaderboardsActions.getLeaderboardById),
        mergeMap((action) =>
          from(
            this.leaderboardsService.leaderboardV2GetById({
              body: {
                appId: environment.projectId,
                id: action.id
              },
            })
          ).pipe(
            map((activeLeaderboard) =>
              LeaderboardsActions.getLeaderboardByIdSuccess(
                { activeLeaderboard }
              )
            ),
            catchError((error) =>
              of(LeaderboardsActions.getLeaderboardByIdFailure({ error }))
            ),
          )
        )
      )
    );

    this.getByAppIds$ = createEffect(() =>
      this.actions$.pipe(
        ofType(LeaderboardsActions.getLeaderboardsByAppIds),
        mergeMap(() =>
          from(
            this.leaderboardsService.leaderboardV2GetByAppIds({
              body: {
                appIds: [environment.projectId],
                limit: 100,
              },
            })
          ).pipe(
            map(({ leaderboards }) =>
              LeaderboardsActions.getLeaderboardsByAppIdsSuccess(
                { leaderboards }
              )
            ),
            catchError((error) =>
              of(LeaderboardsActions.getLeaderboardsByAppIdsFailure({ error }))
            ),
          )
        )
      )
    );



    this.getUserEntry$ = createEffect(() =>
      this.actions$.pipe(
        ofType(LeaderboardsActions.getUserEntry),
        mergeMap(() =>
          from(
            this.leaderboardsService.leaderboardV2GetUserEntry({
              body: {
                appId: environment.projectId,
                leaderboardId: 'pkKi0Rd7lCV06CQqmyvv',
              },
            })
          ).pipe(
            map((entry) =>
              LeaderboardsActions.getUserEntrySuccess({
                entry: entry || {},
              })
            ),
            catchError((error) =>
              of(LeaderboardsActions.getUserEntryFailure({ error }))
            ),
          )
        )
      )
    );
  }
}