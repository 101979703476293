<div class="wrapper">
  <main class="app-container">
    <header class="flex justify-items-between w-screen h-5rem">
      <div class="play-logo flex-1 z-5" #logo>
        <img class="ml-3" src="/assets/images/logo.png" alt="PLAY logo" />
        <p class="play-logo-subtitle ml-3 text-white font-poppins opacity-80">GAMES</p>
      </div>
      <app-header></app-header>
    </header>

    <section class="content h-auto">
      <app-loader></app-loader>
      <router-outlet />
    </section>
  </main>

  <!-- TODO - navbar hidden temporarily -->
  <aside class="mobile-sidebar">
    <div class="container">
      <ul class="mobile-sidebar__nav mobile-nav">
        <li *ngFor="let item of items" class="mobile-nav__item">
          <a class="mobile-nav__link" [routerLink]="item.routerLink" routerLinkActive="active"
            [queryParamsHandling]="'merge'">
            <img *ngIf="item.icon" [src]="item.icon" alt="{{ item.label }} icon" class="w-2rem" />
            <span>{{ item.label }}</span>
          </a>
        </li>
      </ul>
    </div>
  </aside>
  <img class="wrapper__bg-1" src="/assets/images/bg-0.svg" alt="" />
</div>