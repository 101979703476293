import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import {
  FormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { UserUploadProfilePicture$Params } from '@readygg/ng-api/fn/user-data/user-upload-profile-picture';
import { UserUpdateDisplayName$Params } from '@readygg/ng-api/fn/user-data/user-update-display-name';

import { convertToBase64 } from '../../utils/file-utils';
import { UserActions } from '../../state/actions/user.actions';
import { AuthService } from '../../data/services/auth/auth.service';
import { IAuthNewUserData } from '../../models/auth.interface';
import { AppState } from '../../state/app.state';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DialogModule, ButtonModule, InputTextModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent {
  private readonly authService = inject(AuthService);
  @Input() open = false;
  @Output() closeModal = new EventEmitter<void>();
  appId: string | null = null;
  user: IAuthNewUserData | null = null;
  base64String: string | null = null;

  form = new UntypedFormGroup({
    profilePicture: new FormControl<Blob | null>(null),
    username: new FormControl<string>('', [Validators.required]),
  });
  isDialogVisible: any;

  constructor(private messageService: MessageService, private store: Store<AppState>) { }

  ngOnInit(): void {
    this.authService.userData$.subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.form.patchValue({
          username: this.user.displayName || '',
        });
      }
    });
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      convertToBase64(file)
        .then((base64) => {
          this.base64String = base64.split(',')[1];

          if (this.user) {
            if (!this.user.profilePicture) {
              this.user.profilePicture = {
                source: null,
                large: null,
                medium: null,
                small: null,
              };
            }

            this.user.profilePicture.source = base64;
          }
        })
        .catch(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail:
              "We weren't able to upload your profile picture, please try again later.",
          });
        });
    }
  }

  saveProfile() {
    if (this.form.get('profilePicture')?.dirty) {
      const params: UserUploadProfilePicture$Params = {
        body: {
          base64String: this.base64String!,
        },
      };

      this.store.dispatch(UserActions.uploadProfilePicture({ params }));
    }

    if (
      this.form.get('username')?.dirty &&
      this.form.get('username')?.touched
    ) {
      const params: UserUpdateDisplayName$Params = {
        body: {
          displayName: this.form.get('username')?.value.trim() || '',
        },
      };

      this.store.dispatch(UserActions.updateUsername({ params }));
    }
    this.closeModal.emit();
  }
}
