import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../data/services/auth/auth.service';
import { Router } from '@angular/router';
import { IAuthNewUserData, IUserCredentials } from '../../models/auth.interface';
import { MenuItem } from 'primeng/api';
import { ProfileComponent } from '../../pages/profile/profile.component';
import { MenuModule } from 'primeng/menu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { take, distinctUntilChanged, filter } from 'rxjs';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ProfileComponent,
    MenuModule,
    ProgressSpinnerModule,
    ButtonModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  private readonly router = inject(Router);
  isProfileOpen = false;
  loading = false;
  userCredentials: IUserCredentials | null = null;
  userData: IAuthNewUserData | null = null;
  items: MenuItem[] | undefined;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.initializeMenuItems();
    this.subscribeToUserUpdates();
  }

  private initializeMenuItems(): void {
    this.items = [
      {
        label: 'Profile Overview',
        command: () => this.openProfile(),
        icon: 'icon-person',
      },
      {
        label: 'Sign-out',
        icon: 'icon-logout-alt',
        command: () => this.logout(),
      },
    ];
  }

  private subscribeToUserUpdates(): void {
    this.authService.userCredentials$.subscribe((userCredentials) => {
      this.userCredentials = userCredentials;
    });
    this.authService.userData$.subscribe((userData) => {
      this.userData = userData;
    });
  }

  openAuthWebForm() {
    this.loading = true;
    this.authService.isAnonymousSignedIn$.pipe(
      distinctUntilChanged(),
      filter((isAnonymousSignedIn) => isAnonymousSignedIn),
      take(1)
    ).subscribe(() => {
        this.authService.runOAuth();
        this.loading = false;
      }
    );
  }

  openProfile() {
    this.isProfileOpen = true;
  }

  closeProfile() {
    this.isProfileOpen = false;
  }

  saveProfile() {
    this.isProfileOpen = false;
  }

  logout() {
    this.authService.signOut();
    this.router.navigate(['/']);
  }
}
