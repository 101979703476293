import { createReducer, on } from '@ngrx/store';
import { ReadyGGAchievementData, ReadyGGAchievementReward, ReadyGGUserAchievement } from '@readygg/ng-api';
import { AchievementsActions } from '../actions/achievements.actions';

export interface AchievementsState {
  achievements: ReadyGGAchievementData[] | undefined;
  userAchievements: ReadyGGUserAchievement[] | undefined;
  rewards: ReadyGGAchievementReward[] | null;
  rewardsHaveBeenGivenOut: boolean | null;
  error: any | null;
}

export const initialAchievementsState: AchievementsState = {
  achievements: undefined,
  userAchievements: undefined,
  rewards: null,
  rewardsHaveBeenGivenOut: null,
  error: null,
};

export const achievementsReducer = createReducer<AchievementsState>(
  initialAchievementsState,
  on(AchievementsActions.loadAchievementsSuccess, (state, { achievements, userAchievements }) => ({
    ...state,
    achievements,
    userAchievements,
  })),
  on(AchievementsActions.loadAchievementsFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(AchievementsActions.claimAchievementSuccess, (state, { rewardsHaveBeenGivenOut, rewards }) => ({
    ...state,
    rewardsHaveBeenGivenOut,
    rewards,
  })),
  on(AchievementsActions.claimAchievementFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(AchievementsActions.triggerAchievementSuccess, (state, { rewardsHaveBeenGivenOut, rewards }) => ({
    ...state,
    rewardsHaveBeenGivenOut,
    rewards,
  })),
  on(AchievementsActions.triggerAchievementFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
