import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: 'games', pathMatch: 'full' },
  {
    path: 'games',
    loadComponent: () =>
      import('./pages/games-page/games-page.component').then(
        (c) => c.GamesPageComponent
      ),
  },
  {
    path: 'quests',
    loadComponent: () =>
      import('./pages/quests/quests.component').then((c) => c.QuestsComponent),
  },
  {
    path: 'events',
    loadComponent: () =>
      import('./pages/events/events.component').then((c) => c.EventsComponent),
  },
  {
    path: 'events/:name/:id',
    loadComponent: () =>
      import('./pages/events/event/event.component').then((c) => c.EventComponent),
  },
  { path: '**', redirectTo: 'games' },
];
